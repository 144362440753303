.q2-content {
	display: flex;
	flex-direction: column;
}

.q2-notification-dialog {
	overflow: scroll;
}

.--full-height {
	min-height: 100%;
}

// Spacer
@each $size, $lengths in $n-spacers {
	$length-x: map-get($lengths, x);
	$length-y: map-get($lengths, y);
	.--spaced-vertical-#{$size} > * + * {
		margin-top: #{$length-y}px !important;
	}
	.--spaced-horizontal-#{$size} > * + * {
		margin-left: #{$length-x}px !important;
	}
}

.--bold {
	font-weight: 500 !important;
}

.--medium-emphasis {
	color: #8e8e8e;
}
