:root {
	// Primary
	--portals-theme-primary: #{$portals-theme-primary};
	--portals-theme-on-primary: #{$portals-theme-on-primary};

	// Accent
	--portals-theme-accent: #{$portals-theme-accent};
	--portals-theme-on-accent: #{$portals-theme-on-accent};

	// Highlights
	--portals-theme-highlight: #{$portals-theme-highlight};
	--portals-theme-on-highlight: #{$portals-theme-on-highlight};
	--portals-theme-highlight-selected: #{$portals-theme-highlight-selected};

	// Texts
	--portals-theme-primary-text: #{$portals-theme-primary-text};
	--portals-theme-alternative-text: #{$portals-theme-alternative-text};
	--portals-theme-accent-text: #{$portals-theme-accent-text};
	--portals-theme-link-text: #{$portals-theme-link-text};

	// Buttons
	--portals-theme-primary-button: #{$portals-theme-primary-button};
	--portals-theme-accent-button: #{$portals-theme-accent-button};
	--portals-theme-warn-button: #{$portals-theme-warn-button};
	--portals-theme-link-button: #{$portals-theme-link-button};
	--portals-theme-basic-button: #{$portals-theme-basic-button};
	--portals-theme-on-primary-button: #{$portals-theme-on-primary-button};
	--portals-theme-on-accent-button: #{$portals-theme-on-accent-button};
	--portals-theme-on-warn-button: #{$portals-theme-on-warn-button};
	--portals-theme-on-link-button: #{$portals-theme-on-link-button};
	--portals-theme-on-basic-button: #{$portals-theme-on-basic-button};

	// Popover
	--portals-theme-popover: #{$portals-theme-popover};
	--portals-theme-on-popover: #{$portals-theme-on-popover};

	// Surface
	--portals-theme-surface: #{$portals-theme-surface};
	--portals-theme-on-surface: #{$portals-theme-on-surface};
	--portals-theme-on-surface-medium-emphasis: #{$portals-theme-on-surface-medium-emphasis};

	// Background
	--portals-theme-background: #{$portals-theme-background};
	--portals-theme-on-background: #{$portals-theme-on-background};
	--portals-theme-on-background-medium-emphasis: #{$portals-theme-on-background-medium-emphasis};

	// Error
	--portals-theme-error: #{$portals-theme-error};
	--portals-theme-on-error: #{$portals-theme-on-error};

	// Status
	--portals-theme-status-live: #{$portals-theme-status-live};
	--portals-theme-status-live-light: #{$portals-theme-status-live-light};
	--portals-theme-status-draft: #{$portals-theme-status-draft};
	--portals-theme-status-draft-light: #{$portals-theme-status-draft-light};
	--portals-theme-status-pending: #{$portals-theme-status-pending};
	--portals-theme-status-pending-light: #{$portals-theme-status-pending-light};
	--portals-theme-status-expired: #{$portals-theme-status-expired};
	--portals-theme-status-expired-light: #{$portals-theme-status-expired-light};
	--portals-theme-status-archived: #{$portals-theme-status-archived};
	--portals-theme-status--archived-light: #{$portals-theme-status-archived-light};

	// UI Mixins
	--portals-theme-preview-background: #{$portals-theme-preview-background};

	// Cards
	--portals-theme-card-background: #{$portals-theme-card-background};
	--portals-theme-on-card-background-alt: #{$portals-theme-on-card-background-alt};
	--portals-theme-on-card-button: #{$portals-theme-on-card-button};
	--portals-theme-on-card-button-text: #{$portals-theme-on-card-button-text};
	--portals-theme-on-card-link: #{$portals-theme-on-card-link};
}
