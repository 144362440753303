@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
	// Get the color config from the theme.
	$color-config: mat.get-color-config($theme);

	// Get the primary color palette from the color-config.
	$primary-palette: map.get($color-config, 'primary');

	.q2-content {
		&.--primary {
			background-color: mat.get-color-from-palette($primary-palette, 'default');
		}
		&.--accent {
			background-color: mat.get-color-from-palette($primary-palette, 'accent');
		}
		&.--highlight {
			background-color: mat.get-color-from-palette($primary-palette, 'highlight');
		}
	}

	.--text-primary {
		color: mat.get-color-from-palette($primary-palette, 'default');
	}
}

@mixin typography($theme) {
	// Get the typography config from the theme.
	$typography-config: mat.get-typography-config($theme);

	.q2-content {
		font-family: mat.font-family($typography-config);
	}
}

@mixin theme($theme) {
	$color-config: mat.get-color-config($theme);
	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.get-typography-config($theme);
	@if $typography-config != null {
		@include typography($theme);
	}
}
